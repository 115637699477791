/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import ThankYou from '../components/thank-you'
import { halo, intro } from '../content/thank-you'

const HomeThankYou: FC = () => {
  return (
    <Layout>
      <Halo {...halo} />
      <Intro {...intro} />
      <ThankYou containerStyles={{ py: 5, bg: `white_slate8` }} />
    </Layout>
  )
}

export default HomeThankYou
